import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import About from '../components/about'
import Experience from '../components/experience'

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges }
  }
}) => {
  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name='description' content={site.siteMetadata.description} />
        {!site.siteMetadata.w3l_dom_key ? null : <meta name='w3l-domain-verification' content={site.siteMetadata.w3l_dom_key} />}
      </Helmet>

      <h1>Page Not Found</h1>

      <p>The page you were looking for could not be located.</p>

    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query NotFoundErrorPageQuery {
    site {
      siteMetadata {
        title
        description
        w3l_dom_key
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`
